import { pickBy } from 'lodash';

import type { Dealer } from '@autofidev/models/generated';

import type { OfferType } from '@client/types';
import { postMessage } from '../postMessage';

// TODO: come up with a better union type if this bug is fixed:
// https://github.com/microsoft/TypeScript/issues/40803
export type ShiftDigitalEventType = string;

type ShiftDigitalMessage = {
	eventType: ShiftDigitalEventType;
	options: {
		addOnName?: string;
		addOnType?: 'FI' | 'Accessories';
		dealType?: OfferType;
		depositAmt?: number;
		depositMade?: boolean;
		isSubaruGTP: boolean | undefined;
		offerName?: string;
		offerType?: OfferType;
		priceUnlocked: true;
	};
	type: 'autofi-shift-digital-event';
};

type CommonShiftOptions = { dealer: Dealer; isInStore: boolean; isSubaruGTP?: boolean | undefined };

type ShiftDigitalMessageOverload = {
	(
		eventType: 'drImpression' | 'drInitialClick' | 'drTradeInShown' | 'drTradeInStart' | 'drTradeInFinish',
		options: CommonShiftOptions
	): ShiftDigitalMessage | null;

	(
		eventType:
			| 'drPaymentCalcShown'
			| 'drPaymentCalcInteraction'
			| 'drPaymentCalcFinish'
			| 'drLeadFormShown'
			| 'drLeadFormStart'
			| 'drLeadFormFinish'
			| 'drApptSchedShown'
			| 'drApptSchedStart'
			| 'drApptSchedFinish'
			| 'drPreQShown'
			| 'drPreQStart'
			| 'drPreQFinish'
			| 'drCreditAppShown'
			| 'drCreditAppStart'
			| 'drCreditAppFinish'
			| 'drFiShown'
			| 'drFiFinish'
			| 'drAccessoriesShown'
			| 'drAccessoriesFinish'
			| 'drDropSave'
			| 'drContractShown'
			| 'drContractStart',
		options: CommonShiftOptions & { dealType: OfferType }
	): ShiftDigitalMessage | null;

	(
		eventType: 'drAddToCart',
		options: CommonShiftOptions & { addOnName?: string; addOnType?: 'FI' | 'Accessories'; dealType: OfferType }
	): ShiftDigitalMessage | null;

	(
		eventType: 'drContractFinish',
		options: CommonShiftOptions & { dealType: OfferType; depositMade?: boolean }
	): ShiftDigitalMessage | null;

	(
		eventType: 'drOfferAdded',
		options: CommonShiftOptions & { dealType: OfferType; offerName: string; offerType: OfferType }
	): ShiftDigitalMessage | null;

	(
		eventType: ShiftDigitalEventType,
		options: CommonShiftOptions & {
			addOnName?: string;
			addOnType?: string;
			dealType?: OfferType;
			depositMade?: boolean;
			offerName?: string;
			offerType?: OfferType;
		}
	): ShiftDigitalMessage | null;
};

const makeShiftDigitalMessage: ShiftDigitalMessageOverload = (
	eventType: any,
	options: CommonShiftOptions & {
		addOnName?: string;
		addOnType?: string;
		dealType?: OfferType;
		depositMade?: boolean;
		offerName?: string;
		offerType?: OfferType;
	}
) => {
	const { addOnName, addOnType, dealer, depositMade = false, isInStore, isSubaruGTP, offerName, offerType } = options;
	const { brand, settings } = dealer;
	const { shiftDigital = {} } = settings.analytics || {};
	const { enabled: isShiftMember, trackingEnabled } = shiftDigital;

	if (isInStore && brand !== 'kia') {
		// Track event in-store only for Kia dealers, and out-of-store for all
		// Shift members.
		return null;
	}

	if (!(isShiftMember && trackingEnabled)) {
		return null;
	}

	const depositAmt = depositMade ? settings.deposit?.amount : 0;

	return {
		type: 'autofi-shift-digital-event',
		eventType,
		options: {
			isSubaruGTP,

			// priceUnlocked should always be true for events sent from deal maker
			priceUnlocked: true,
			...pickBy({ addOnName, addOnType, dealType: offerType, offerName }, (value) => value !== undefined),
			...(eventType === 'drOfferAdded' && { offerType }),
			...(eventType === 'drContractFinish' && { depositMade, depositAmt }),
		},
	};
};

// trackShiftDigitalEvent tries to send a message to the page containing
// the deal maker iframe. If such a page exists, panda is expected to add
// some properties (such as digRet.vehicle and digRet.dealId), then pass it
// to the top-level window.sd function to send the event, with all the
// required data, to Shift.
const trackShiftDigitalEvent = (
	eventType: ShiftDigitalEventType,
	options: CommonShiftOptions & {
		addOnName?: string;
		addOnType?: 'FI' | 'Accessories';
		depositMade?: boolean;
		offerName?: string;
		offerType?: OfferType;
	}
): void => {
	const message = makeShiftDigitalMessage(eventType, options);
	if (message) {
		postMessage(message);
	}
};

export { trackShiftDigitalEvent };
