import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import './globalize';

import { StandAloneCreditApplication } from '@sac/index';
import { isFieldEnabled } from '@client/lib/helpers/pii';
import { createStoreWithMiddleware } from '@client/store';
import { HYDRATE_ENV } from '@client/store/actions/types';

const enabledField = (name) => isFieldEnabled(window.dealer, name, 'applicant');

const reduxStore = createStoreWithMiddleware();

reduxStore.dispatch({
	type: HYDRATE_ENV,
	payload: { launchDarklyFeatureFlags: window.launchDarklyFeatureFlags },
});

const props = {
	fields: {
		renderFieldEmail: enabledField('email'),
		renderFieldTitle: enabledField('title'),
		renderFieldNameFirst: enabledField('first'),
		renderFieldNameLast: enabledField('last'),
		renderFieldNameMiddle: enabledField('middle'),
		renderFieldSuffix: enabledField('suffix'),
		renderFieldPhone: enabledField('phone'),
		renderFieldTaxExempt: enabledField('taxExempt'),
	},
	isStandAlone: true,
	dealer: window.dealer,
};

ReactDOM.render(
	<Provider store={reduxStore}>
		<StandAloneCreditApplication {...props} />
	</Provider>,
	document.querySelector('#react--container')
);
